import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../api/axios"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    login(context, payload) {
      console.log(payload);
      return axios.post(`/login`, {
        username: payload.username,
        password: payload.password
      })
    },
    addCostumer(context, payload) {
      let data = {
        nama: payload.nama,
        tglLahir: payload.tglLahir,
        tempatLahir: payload.tempatLahir,
        alamat: payload.alamat,
        notel: payload.notel,
        noKtp: payload.noKtp,
        pekerjaan: payload.pekerjaan,
        keterangan: payload.keterangan,
        noNpwp: payload.noNpwp
      }
      return axios({
        method: "post",
        url: "/costumer",
        data
      })
    },
    deleteCostumer(context, id) {
      return axios.delete(`/costumer/${id}`, {
        headers: {
          access_token: localStorage.getItem("access_token")
        }
      })
    }
  },
  modules: {
  }
})
