<template>
  <b-container class="bv-example-row">
    <b-row class="bv-example-row" align-v="center">
      <b-col
        ><img src="../assets/konfigurasi.png" alt="" id="logo" />
        <b-form @submit.prevent="addCostumer">
          <!-- line1 -->
          <div class="row container-fluid">
            <div class="col justify-content-left">
              <b-form-group id="input-group-1" label-for="input-1" label="">
                <b-form-input
                  id="input-1"
                  v-model="nama"
                  type="text"
                  placeholder="Nama Lengkap"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group id="input-group-1" label-for="input-1" label="">
                <b-form-input
                  id="input-1"
                  v-model="noKtp"
                  type="number"
                  placeholder="Nomor KTP"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <!-- line2 -->
          <div id="line2" class="row container-fluid mt-2">
            <b-form-group id="input-group-1" label-for="input-1">
              <b-form-input
                id="alamat"
                v-model="alamat"
                type="text"
                placeholder="Alamat"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <!-- line3 -->
          <div class="row container-fluid justify-content-md-center mt-2">
            <div class="col">
              <b-form-group id="input-group-1" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="tempatLahir"
                  type="text"
                  placeholder="Tempat Lahir"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <!-- Line 4A -->
          <div class="row container-fluid mt-2">
            <b-form-group id="input-group-1" label-for="input-1">
              <b-form-input
                id="tanggal"
                v-model="tglLahir"
                type="date"
                placeholder="Tanggal lahir"
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <!-- line4b -->
          <div class="row container-fluid justify-content-md-center mt-2">
            <div class="col">
              <b-form-group id="input-group-1" label-for="input-1" label="">
                <b-form-input
                  id="input-1"
                  v-model="notel"
                  type="number"
                  placeholder="No.Telepon"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group id="input-group-1" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="pekerjaan"
                  type="text"
                  placeholder="Pekerjaan"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <!-- line5 -->

          <div class="row container-fluid justify-content-md-center mt-2">
            <div class="col">
              <b-form-group id="input-group-1" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="noNpwp"
                  type="text"
                  placeholder="No. NPWP"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group id="input-group-1" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="keterangan"
                  type="text"
                  placeholder="keterangan"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <b-button id="submit" type="submit" variant="primary"
            >Submit</b-button
          >
        </b-form></b-col
      >
      <b-col lg="4"
        ><img src="../assets/logo-input.jpeg" id="gambar2" alt=""
      /></b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  name: "AddCostumer",
  data() {
    return {
      nama: "",
      tglLahir: "",
      tempatLahir: "",
      alamat: "",
      notel: "",
      noKtp: "",
      noNpwp: "",
      pekerjaan: "",
      keterangan: ""
    };
  },
  methods: {
    addCostumer() {
      this.$store
        .dispatch("addCostumer", {
          nama: this.nama,
          tglLahir: this.tglLahir,
          tempatLahir: this.tempatLahir,
          alamat: this.alamat,
          notel: this.notel,
          noKtp: this.noKtp,
          pekerjaan: this.pekerjaan,
          noNpwp: this.noNpwp,
          keterangan: this.keterangan
        })
        .then(data => {
          console.log(data);
          alert(
            `Terima Kasih ${this.nama} Data Anda Telah Berhasil Ditambahkan`
          );
          this.$router.push("/");
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style>
#gambar2 {
  /* max-height: 100vh; */
  max-width: 40vh;
}
#form {
  padding: 0;
}

#logo {
  margin-bottom: 5vh;
  text-align: left;
  padding: 0;
  max-height: 6vh;
  max-width: 30vh;
}
#line2 {
  text-align: left;
}

#submit {
  font-size: 3vh;
  margin-top: 15px;
}
p {
  font-size: 25px;
  font-weight: bold;
}

#input-1 {
  display: initial;
  margin-top: 5px;
  /* width: 7cm; */
  justify-content: center;
}
#formPage2 {
  background-color: rgb(255, 255, 255);
  text-align: left;

  padding: 0;
  /* height: 100vh; */
}
</style>