<template>
  <div>
    <b-row style="margin-top:3vh">
      <b-col class="text-left">
        <img src="../assets/konfigurasi.png" alt id="gambarDatabase" />
      </b-col>
      <b-col class="text-center" id="HeadDatabase">
        <p id="juduldatabase">DATABASE NUP</p>
      </b-col>
      <b-col class="text-right" align-v="start">
        <b-button
          variant="primary"
          id="buttonLogout"
          align-v="start"
          @click.prevent="logout()"
        >LOGOUT</b-button>
      </b-col>
    </b-row>
    <table id="example1" class="table table-bordered table-striped">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Nama</th>
          <th scope="col">Tempat Lahir</th>
          <th scope="col">Tanggal Lahir</th>
          <th scope="col">Alamat</th>
          <th scope="col">No Telepon</th>
          <th scope="col">No. KTP</th>
          <th scope="col">Pekerjaan</th>
          <th scope="col">No. NPWP</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody v-for="list in costumerList" :key="list.id" :value="list.id">
        <tr>
          <th scope="row">{{ list.id }}</th>
          <td>{{ list.nama }}</td>
          <td>{{ list.tempatLahir }}</td>
          <td>{{ list.tglLahir.slice(0, 10) }}</td>
          <td>{{ list.alamat }}</td>
          <td>{{ list.noTel }}</td>
          <td>{{ list.noKtp }}</td>
          <td>{{ list.pekerjaan }}</td>
          <td>{{ list.noNpwp }}</td>
          <td>
            <b-button type="button" variant="success" @click="confirmDelete(list.id)">DELETE</b-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "../api/axios";

export default {
  name: "CostumerList",
  data() {
    return {
      costumerList: []
    };
  },
  methods: {
    fatchList() {
      return axios
        .get("./costumer", {
          headers: {
            access_token: localStorage.getItem("access_token")
          }
        })
        .then(({ data }) => {
          this.costumerList = data;
        });
    },
    confirmDelete(id) {
      let yes = confirm("Yakiin delete?");
      if (yes) {
        this.deleteCost(id);
      } else {
        alert("Berhasil di batalkan");
      }
    },

    deleteCost(id) {
      this.$store
        .dispatch("deleteCostumer", id)
        .then(data => {
          alert("data Berhasil dihapuskan");
          localStorage.getItem("access_token");
          this.fatchList();
        })
        .catch(err => {
          console.log(err);
        });
    },
    logout() {
      localStorage.clear();
      this.$router.push("/database/login");
    }
  },
  created() {
    this.fatchList();
  }
};
</script>

<style>
#buttonLoGOUT {
  display: initial;
  justify-content: center;
}

#gambarDatabase {
  margin-top: 2vh;
  max-height: 6vh;
  max-width: 30vh;
}
@media screen and(max-width: 800px) {
  #juduldatabase {
    display: none;
  }
  .gambarDatabase {
    display: none;
  }
  .headList {
    display: flex;
    /* justify-content: center; */
  }
}
</style>