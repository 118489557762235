<template>
  <div class="row justify-content-md-center">
    <b-row id="form" class="col-6 justify-content-md-center">
      <img src="../assets/logoPerumnas.jpeg" alt id="logoPerumnas" />
      <b-form @submit.prevent="login">
        <b-form-group id="username" label-for="input-1">
          <b-form-input
            id="username"
            v-model="username"
            type="text"
            placeholder="username"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="password" label-for="input-1">
          <b-form-input
            id="password"
            v-model="password"
            placeholder="password"
            type="password"
            required
          ></b-form-input>
        </b-form-group>
        <b-button id="submitLogin" type="submit" variant="primary">Submit</b-button>
      </b-form>
      <p id="tagname">© 2021 Andi Riswandi</p>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: ""
    };
  },
  methods: {
    login() {
      let data = {
        username: this.username,
        password: this.password
      };
      this.$store.dispatch("login", data).then(data => {
        alert("Selamat Datang Bossku!!");
        localStorage.setItem("access_token", data.data.access_token);
        console.log(data);
        this.$router.push("/database/list");
      });
    }
  }
};
</script>

<style>
#tagname {
  margin-top: 4vh;
  color: rgb(85, 85, 83);
  font-family: "Roboto", sans-serif;
  font-size: 2vh;
}
#logoPerumnas {
  /* height: 15cm; */
  width: 130;
}

#submitLogin {
  margin-top: 15px;
}
p {
  font-size: 25px;
  font-weight: bold;
}
#username {
  display: initial;
  margin-top: 5px;
  width: 7cm;
  justify-content: center;
}
#password {
  display: initial;
  margin-top: 5px;
  width: 7cm;
  justify-content: center;
}
#formPage {
  /* min-height: 100vh; */
  /* height: 100vh; */
}
@media screen and (max-width: 800px) {
  .row {
    /* background-color: cadetblue; */
    margin: 0 0;
  }
  #submitLogin {
    font-size: 12px;
    margin-top: 15px;
  }
  #username {
    /* display: flex; */
    margin-top: 5px;
    height: 30px;
    width: 200px;
    justify-content: center;
  }
  #password {
    /* display: flex; */
    /* margin-top: 5px; */
    height: 30px;
    width: 200px;
    justify-content: center;
  }
  #tagname {
    margin-top: 4vh;
    color: rgb(85, 85, 83);
    font-family: "Roboto", sans-serif;
    font-size: 11px;
  }
}
</style>