import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "../views/Login.vue"
import CostumerList from "../views/CostumerList.vue"
import AddCostumer from "../views/AddCostumer.vue"

Vue.use(VueRouter)

const routes = [

  {
    path: '/database/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/addcostumer',
    name: 'AddCostumer',
    component: AddCostumer
  },
  {
    path: '/database/list',
    name: 'CostumerList',
    component: CostumerList,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => { // pengecekan meta required
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('access_token')) {
      alert("Anda Harus Login terlebih dahulu ya..!!")
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
